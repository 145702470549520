import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select, Button, Switch, Dialog, DialogTitle, DialogContent, Autocomplete, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { getDateForChart, getDecodeURI, getEncodedURI, getMinutes, getSingleRecordChartLable, getTimezoneCity, readableBytesAsGB, readableBytesAsMB } from "../../../utils/util";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { getCaptiveDashPlansSubscribersHistogram, getCaptiveDashPlansUsageLineCharts, getCaptiveDashPlansSubscribersLineCharts } from "../../../actions/Users/authenticateCaptiveDahshboard";
import { GET_CAPTIVE_DASHBOARD_PLANS_USAGE_LINE_CHART, GET_CAPTIVE_DASHBOARD_PLANS_SUBSCRIBERS_LINE_CHART, GET_CAPTIVE_DASHBOARD_PLANS_SUBSCRIBERS_HISTOGRAM } from "../../../actions/types";
import _ from "lodash";
import moment from "moment";
import Download from '../../../asset/image/DownloadUsage.svg';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import BarChart from "../captiveComponent/BarChart";
import ModeIcon from '@mui/icons-material/Mode';
import close from "../../../asset/image/cross.svg";
import barIcon from '../../../asset/image/bar_chart_icon.svg';
import lineIcon from '../../../asset/image/line_chart_icon.svg';
import bar_chart from '../../../asset/image/bar_chart_light.svg';
import line_chart from '../../../asset/image/line_chart.svg';

const PlansCharts = (props) => {
    const { authReducer, newSummaryStartDate, newSummaryEndDate, location, history, vessels, getCaptiveDashPlansSubscribersHistogram, getCDPlansSubscribersHistogram, getCaptiveDashPlansUsageLineCharts, getCDPlansUsageLineChart, getCaptiveDashPlansSubscribersLineCharts, getCDPlansSubscribersLineChart, topcardSelected, selectedTab, values,setValues, applyFilterClick } = props;
    const _q = getDecodeURI(location?.search);
    const [lineChartData, setLineChartData] = useState<any>([]);
    const [barChartData, setBarChartData] = useState<any>([]);
    const [resetDate, setResetDate] = useState<any>([]);
    const [recLimit, setRecLimit] = useState(10);
    const timeZone = getTimezoneCity(authReducer?.userTimezone);
    const [ chartView, setChartView] = useState(_q.hasOwnProperty('planChartView') ? _q.planChartView : 'column');
    const [ lineChartView, setLineChartView] = useState(_q.hasOwnProperty('planLineChartView') ? _q.planLineChartView : 'subscribers');
    const [barChartDataUnit, setBarChartDataUnit] = useState('GB');
    const getInterval = (minutes) => {
        if (minutes <= 720) {
            return "5 minutes";
        } else if (minutes > 720 && minutes <= 1440) {
            return "15 minutes";
        } else if (minutes > 1440 && minutes <= 10080) {
            return "1 hour";
        } else {
            return "12 hour";
        }
    }
    const [selectedBin, setSelectedBin] = useState(getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)));
    const { selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal } = values;
    const dispatch = useDispatch()
    const [histogramCategories, setHistogramCategories] = useState<any>([]);
    const [binSize, setBinSize] = useState(10);
    const [popupOpen, setPopupOpen] = useState(false);
    const [chartType, setChartType] = useState('spline');
    const [minBucket, setMinBucket] = useState<any>()
    const [maxBucket, setMaxBucket] = useState<any>()
    const [rangeList, setRangeList] = useState<any>([])
    const [selectedSiteDetails, setSelectedSiteDetails] = useState<any>([]);
    const [siteDetailsPopup, setSiteDetailsPopup] = useState(false);

    const HighchartsInstance = { ...Highcharts };
    Exporting(HighchartsInstance);
    ExportData(HighchartsInstance);
    Highcharts.setOptions({
        exporting: { enabled: false },
    });
    useEffect(() => {
        const _interval = getInterval(
            Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)
        );
        setSelectedBin(_interval);
        if (vessels?.length > 0) {
            if (chartView === 'column') {
                getCaptiveDashPlansSubscribersHistogram(vessels, newSummaryStartDate, newSummaryEndDate, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal, binSize, minBucket, maxBucket);
            } else if (chartView === 'spline') {
                if (lineChartView === 'subscribers') {
                    getCaptiveDashPlansSubscribersLineCharts(vessels, newSummaryStartDate, newSummaryEndDate, _interval, recLimit, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal);
                } else if (lineChartView === 'usage') {
                    getCaptiveDashPlansUsageLineCharts(vessels, newSummaryStartDate, newSummaryEndDate, _interval, recLimit, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal);
                }
            }
        } else {
            setLineChartData([]);
            setBarChartData([]);
        }
    }, [vessels, newSummaryStartDate, newSummaryEndDate, chartView, lineChartView, recLimit]);

    const colors = ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
        '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
        '#1b4dac', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
        '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
        '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
    ]

    useEffect(() => {
        if (getCDPlansSubscribersHistogram?.hasOwnProperty('data') && _.isEmpty(getCDPlansSubscribersHistogram.data)) {
            setBarChartData([]);
            setHistogramCategories([]);
            setMinBucket(0);
            setMaxBucket([0]);
            setRangeList([]);
            setPopupOpen(false);
            return;
        }
        if (!_.isEmpty(getCDPlansSubscribersHistogram)) {
            const data = getCDPlansSubscribersHistogram.hasOwnProperty('data') ? getCDPlansSubscribersHistogram.data : [];
            const gbValue = Math.pow(1000, 3);
            const isGBCrossed = data?.some(row => Number(row.lower_range) > gbValue || Number(row.upper_range) > gbValue);
            const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;

            const formattedData = data.map(el => ({
                // x: (el.lower_range),
                y: el.usersCount,
                name: `${el.lower_range} - ${el.upper_range}`,
                planDetails: el.planDetails ? JSON.parse(`[${el.planDetails.replace(/'/g, "")}]`)?.[0] : []
            }));
    
            const categoriesData = data.map(el => `${convertBytes(el.lower_range, false)} - ${convertBytes(el.upper_range, false)}`);
            setBarChartDataUnit(isGBCrossed ? 'GB' : 'MB');
    
            setBarChartData(formattedData);
            setHistogramCategories(categoriesData);
            setPopupOpen(false);

            const minRange = Math.floor(Math.min(...data.map(el => el.lower_range)));
            const maxRange = Math.ceil(Math.max(...data.map(el => el.upper_range)));
            const maxListSize = 10;
            const step = Math.max(1, Math.ceil((maxRange - minRange) / (maxListSize - 1)));
            let uniqueValues = new Set();
            let dynamicRange: any = [];
            for (let i = 0; i < maxListSize; i++) {
                let rawValue = minRange + i * step;
                let value = Math.ceil(Number(convertBytes(rawValue, false)));
                if (!uniqueValues.has(value)) {
                    uniqueValues.add(value);
                    dynamicRange.push({ label: `${value} `, value });
                }
            }

            let maxConverted = Math.ceil(Number(convertBytes(maxRange, false)));
            if (!uniqueValues.has(maxConverted)) {
                dynamicRange.push({ label: `${maxConverted} `, value: maxConverted });
            }

            dynamicRange = dynamicRange.slice(0, maxListSize);

            setMinBucket(dynamicRange?.[0]['value'])
            setMaxBucket(dynamicRange?.[dynamicRange.length - 1]['value'])
            setRangeList(dynamicRange);
        }
        if (!_.isEmpty(getCDPlansUsageLineChart)) {
            const data = getCDPlansUsageLineChart.hasOwnProperty('data') ? getCDPlansUsageLineChart.data : {};
            if (data) {
                const series = data?.quotaUsed?.series || {};
                const seriesKeys = Object.keys(series);
                const gbValue = Math.pow(1000, 3);
                const isGBCrossed = seriesKeys.some(key => {
                    const seriesValue = series[key];
                    return (_.isArray(seriesValue) && seriesValue.some(item => item.y > gbValue))
                })
                const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
                const lineChartData: any = data?.quotaUsed?.series && Object.keys(data?.quotaUsed?.series)?.map((key, i) => {
                    data?.quotaUsed?.series[key]?.forEach((el, i) => {
                        series[key][i].y = Number(convertBytes(el.y, false))
                    })
                    return { name: key, data: data?.quotaUsed?.series[key], marker: { symbol: 'circle', enabled: false }, visible: true, color: colors[i] }
                })
                setLineChartData(lineChartData);
                setBarChartDataUnit(isGBCrossed ? 'GB' : 'MB');
            }
        }
        if (!_.isEmpty(getCDPlansSubscribersLineChart)) {
            const data = getCDPlansSubscribersLineChart.hasOwnProperty('data') ? getCDPlansSubscribersLineChart.data : {};
            if (data) {
                const lineChartData: any = data?.subscribers?.series && Object.keys(data?.subscribers?.series)?.map((key, i) => {
                    return { name: key.split(' | ')[1], data: data?.subscribers?.series[key], marker: { symbol: 'circle', enabled: false }, visible: true, color: colors[i] }
                })
                setLineChartData(lineChartData);
            }
        }
        if (!_.isEmpty(getCDPlansUsageLineChart) || !_.isEmpty(getCDPlansSubscribersLineChart)) {
            dispatch({ type: GET_CAPTIVE_DASHBOARD_PLANS_SUBSCRIBERS_HISTOGRAM, payload: {} });
            dispatch({ type: GET_CAPTIVE_DASHBOARD_PLANS_USAGE_LINE_CHART, payload: {} });
            dispatch({ type: GET_CAPTIVE_DASHBOARD_PLANS_SUBSCRIBERS_LINE_CHART, payload: {} });
        }
    }, [getCDPlansSubscribersHistogram, getCDPlansUsageLineChart, getCDPlansSubscribersLineChart])

    const pinchAndResetChart = (start, end, interval) => {
        let params = getDecodeURI(location?.search);
        params.startDate = start.valueOf();
        params.endDate = end.valueOf();
        params.interval = interval;
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const chartAreaSelection = () => {
        return (event: any) => {
            let start: any = moment(new Date(Math.ceil(event.xAxis[0].min)));
            let end = moment(new Date(Math.floor(event.xAxis[0].max)));
            pinchAndResetChart(start, end, 'customDates');
            let arr: any = resetDate;
            arr.push({ startDate: start, endDate: end });
            setResetDate(arr);
            return false;
        }
    }

    const handleResetZoom = () => {
        if (resetDate?.length == 0) {
            return;
        }
        let arr: any = resetDate;
        arr.pop();
        setResetDate(arr);
        if (arr?.length == 0) {
            const start = moment(Date.now()).subtract(1, 'hour');
            const end = moment(Date.now());
            pinchAndResetChart(start, end, '1h');
        }
        else {
            let obj = arr[arr?.length - 1];
            let start = obj.startDate;
            let end = obj.endDate;
            pinchAndResetChart(start, end, 'customDates');
        }
    }

    const handleDefaultResetZoom = () => {
        setResetDate([]);
        const start = moment(Date.now()).subtract(1, 'hour');
        const end = moment(Date.now());
        pinchAndResetChart(start, end, '1h');
    }

    const handleBarClick = (event) => {
        if (event.point.planDetails) {
            setSelectedSiteDetails(event.point.planDetails);
            setSiteDetailsPopup(true);
        }
    };

    const barOptions = {
        time: {
            timezone: timeZone,
        },
        chart: {
            type: chartView == 'column' ? 'column' : chartType,
            height: '260px',
            plotBorderWidth: 1,
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
        },
        title: {
            text: '',
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500',
            },
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false,
                },
                cursor: 'pointer'
            },
            series: {
                point: {
                    events: {
                        click: handleBarClick
                    }
                }
            }
        },
        tooltip: {
            headerFormat: '',
            pointFormat: chartView == 'column' ? `<b style="color: #7e8089">Subscribers: {point.y}</b>` : `<span style="color: #7e8089">{point.x}</span> <br/> <b style="color: #fff">{point.z}</b><br/>`,
            valueDecimals: 0,
            borderWidth: 1,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#060606',
            cursor: 'pointer',
            style: {
                pointerEvents: 'auto'
            },
            formatter: function (this: any) {
                if (chartView === 'spline') {
                    const options: Intl.DateTimeFormatOptions = {
                        weekday: 'long',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false,
                    };
        
                    const timeZoneFormattedDate = new Intl.DateTimeFormat('en-US', {
                        ...options,
                        timeZone: timeZone,
                    }).format(new Date(this.x));
        
                    return `
                        <b style="color: #7e8089">${timeZoneFormattedDate}</b><br/>
                        <b style="color: #7e8089">${this.series.name}</b>: 
                        <b style="color: #fff">${this.point.y} ${lineChartView == 'usage' ? barChartDataUnit : ''}</b>
                    `;
                } else {
                    const planDetails = this.point.planDetails || [];
                    const maxSitesToShow = 10;
                
                    let subscriberNames = planDetails.map(site => site.userName).slice(0, maxSitesToShow).join(', ');
                    let remainingCount = planDetails.length - maxSitesToShow;
                
                    if (remainingCount > 0) {
                        subscriberNames += ` ( + ${remainingCount} more)`;
                    }

                    setTimeout(() => {
                        const tooltipElement = document.querySelector('.tooltip-clickable');
                        if (tooltipElement) {
                            tooltipElement.addEventListener('click', () => handleBarClick({ point: this.point }));
                        }
                    }, 100);
                
                    return `<div class="tooltip-clickable" data-point-index="${this.point.index}" style="cursor: pointer;">
                            <b style="color: #7e8089">Sites: <b style="color: #fff">${this.y}</b></b><br/>
                            <b style="color: #fff">${subscriberNames}</b>
                        </div>`
                }
            }
        },
        xAxis: {
            title: {
                text: '',
            },
            categories: chartView == 'column' ? histogramCategories : null,
            type: chartView == 'column' ? 'category' : 'datetime',
            gridLineWidth: 0.5,
            labels: {
                format: chartView === 'column' ? `{value} ${barChartDataUnit}` : getDateForChart(lineChartData) && getSingleRecordChartLable(lineChartData, timeZone),
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontWeight: '500',
                },
            },
        },
        yAxis: {
            title: {
                text: chartView == 'spline' && lineChartView == 'usage' ? `Usage (${barChartDataUnit})` : '',
            },
            gridLineWidth: 1,
            tickInterval: 1,
            labels: {
                format: '{value}',
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontWeight: '500',
                },
            },
        },
        exporting: {
            enabled: false,
            csv: {
                columnHeaderFormatter: (item, key) => {
                    if(chartView == 'spline'){
                        if (item.isXAxis) {
                            return 'Time';
                        }
                        return `${item.name} (${barChartDataUnit})`;
                    }
                    else{
                        if (item.isXAxis) {
                            return 'Percentage';
                        }
                        return `Plans Count`;
                    }

                }
            }
        },
        series: chartView == 'column' ? [{ data: barChartData, showInLegend: false, color: "#86c6df" }] : lineChartData
    };
    
    const handleBinChange = (e) => {
        const value = e.target.value;
        setSelectedBin(value);
        if (vessels?.length > 0) {
            if (lineChartView === 'subscribers') {
                getCaptiveDashPlansSubscribersLineCharts(vessels, newSummaryStartDate, newSummaryEndDate, value, recLimit, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal);
            } else if (lineChartView === 'usage') {
                getCaptiveDashPlansUsageLineCharts(vessels, newSummaryStartDate, newSummaryEndDate, value, recLimit, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal);
            }
        }
    };

    const handleChangeTopAppLimit = (e) => {
        const limit = e.target.value;
        setRecLimit(limit);
    };

    const handleChangeToggle = (e) => {
        let params: any = getDecodeURI(location?.search);
        params.planChartView = e.target.checked ? 'spline' : 'column';
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
        if(e.target.checked) { 
            setChartView('spline');
            setChartType('spline');
        }
        else { 
            setChartView('column')
            setLineChartView('subscribers');
        }
    }

    const handleLegendClick = (app, color, index) => {
        let all_true = true;
        let updatedData: any = [...lineChartData];
        let all_off_turn_on = 0;
        let click = 0;

        updatedData?.forEach((ele, i) => {
            if (!ele.visible) {
                all_true = false;
                all_off_turn_on += 1;
            }
            if (ele.visible) {
                click = i;
            }
        })

        if (click == index && all_off_turn_on == updatedData.length - 1) {
            updatedData?.forEach((ele, i) => {
                updatedData[i] = { ...ele, visible: true }
            })
            setLineChartData([...updatedData]);
        }
        else if (all_true) {
            updatedData?.forEach((ele, i) => {
                updatedData[i] = { ...ele, visible: false }
            })
            updatedData[index].visible = true;
            setLineChartData([...updatedData]);
        }
        else {
            updatedData.forEach((ele, i) => {
                updatedData[i] = ele;
            })
            updatedData[index].visible = !updatedData[index].visible;
            setLineChartData([...updatedData]);
        }
    }

    const chartRef = useRef<HighchartsReact.RefObject>(null);

    const handleDownloadCSV = () => {
        if (chartRef.current?.chart) {
            const csvData = chartRef.current.chart.getCSV();
            let csvLines = csvData.split('\n');
    
            if (chartView === 'column') {
                const headers = csvLines[0].split(',');
                headers[0] = `Usage Range (${barChartDataUnit})`; 
                headers[1] = 'Subscribers Count';
                headers.push('Subscriber Usage');
                csvLines[0] = headers.join(',');
    
                csvLines = csvLines.map((line, index) => {
                    if (index === 0) return line;
    
                    const columns = line.split(',');
                    const [minStr, maxStr] = columns[0].split(' - ').map(value => value.replace(/"/g, '').trim());
                    const minRange = parseInt(minStr, 10);
                    const maxRange = parseInt(maxStr, 10);
                    columns[0] = barChartDataUnit == 'GB' ? `${readableBytesAsGB(minRange, false)} - ${readableBytesAsGB(maxRange, false)}` : `${readableBytesAsMB(minRange, false)} - ${readableBytesAsMB(maxRange, false)}`;
    
                    const site = barChartData[index - 1];
                    if (!site || !site.planDetails) return `${columns.join(',')},`;
    
                    const planDetails = site.planDetails
                        .map(detail => `${detail.userName}(${readableBytesAsGB(detail.quotaUsed)})`)
                        .join(' | ');
    
                    return `${columns.join(',')},"${planDetails}"`;
                });
            }
    
            const finalCSV = csvLines.join('\n');
    
            const blob = new Blob([finalCSV], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'captive_plans_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('Chart reference is null or not initialized.');
        }
    };

    const handleBucket = (e) => {
        setBinSize(e.target.value);
    }

    const handleHistogramClick = () => {
        const GB_TO_BYTES = Math.pow(1000, 3);
        const MB_TO_BYTES = Math.pow(1000, 2);

        const minRangeInBytes = barChartDataUnit === 'GB' ? minBucket * GB_TO_BYTES : minBucket * MB_TO_BYTES;
        const maxRangeInBytes = barChartDataUnit === 'GB' ? maxBucket * GB_TO_BYTES : maxBucket * MB_TO_BYTES;
        getCaptiveDashPlansSubscribersHistogram(vessels, newSummaryStartDate, newSummaryEndDate, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal, binSize, minRangeInBytes, maxRangeInBytes);
    }

    const handleHistogramResetClick = () => {
        getCaptiveDashPlansSubscribersHistogram(vessels, newSummaryStartDate, newSummaryEndDate, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal, binSize, 0, 0);
    }

    return (
        <div className="new-summary-chart-container charts-container-captive-dashboard" style={{ marginTop: '15px' }}>
            <div className="alignTopbarItemsCenter">
                <div className="download-summary-btn margin-left-auto" onClick={handleDownloadCSV}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
            </div>
            <Grid container spacing={2} className='new-summary-chart-component'>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={0} className="usage-per-site-pie">
                        {/* <Grid item xs={3} sm={3} md={3} lg={3} className="pie-chart-summary">
                            <TopPlansPie vessels={vessels} values={values} setValues={setValues} applyFilterClick={applyFilterClick} chartView={lineChartView} setChartView={setLineChartView} showToggle={chartView=='spline'} />
                        </Grid> */}
                        <Grid item xs={5} sm={5} md={5} lg={5} className="pie-chart-summary">
                            <BarChart vessels={vessels} topcardSelected={topcardSelected} selectedTab={selectedTab} values={values} setValues={setValues} applyFilterClick={applyFilterClick} chartView={lineChartView} setChartView={setLineChartView} showToggle={chartView=='spline'} isHistogram={chartView == 'column'} />
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={7} className="site-line-chart-title-div">
                            <Grid className="legends-pie-new-container site-line-chart-title-text">
                                <Grid>
                                    <span className="title-text">Histogram</span>
                                    <Switch
                                        checked={chartView == 'spline' ? true : false}
                                        onChange={handleChangeToggle}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        size="small"
                                    />
                                    <span className="title-text usage-trend">Trend</span>
                                </Grid>
                                <Grid className="legends-view-images legends-view-images-sites height-24px">
                                    {chartView == 'column' && <>
                                        <Grid className="summary-pie-bar-legends">
                                            <div className="summary-pie-usage-dot" style={{ background: '#86c6df' }}></div>
                                            <span className="barChart-legends">Subscribers</span>
                                        </Grid>
                                        <FormControlLabel
                                            value="Zoom Reset"
                                            control={<IconButton onClick={() => { setPopupOpen(true) }}><ModeIcon style={{ height: "18px", width: "24px" }} /></IconButton>}
                                            label=""
                                            labelPlacement="start"
                                        />
                                    </>}
                                    {chartView == 'spline' && <>
                                        <Grid className="pieChat-selectTag">
                                            <FormControl variant="standard" className='selectEntry-pie'>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={selectedBin}
                                                    onChange={handleBinChange}
                                                >
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 5 && <MenuItem value={'5 minutes'}>5m</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10 && <MenuItem value={'10 minutes'}>10m</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 15 && <MenuItem value={'15 minutes'}>15m</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 30 && <MenuItem value={'30 minutes'}>30m</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 60 && <MenuItem value={'1 hour'}>1h</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 120 && <MenuItem value={'2 hour'}>2h</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 180 && <MenuItem value={'3 hour'}>3h</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 360 && <MenuItem value={'6 hour'}>6h</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 720 && <MenuItem value={'12 hour'}>12h</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 1440 && <MenuItem value={'1 day'}>1d</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10080 && <MenuItem value={'7 day'}>7d</MenuItem>}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <div className="siteCharts__filters-reset-zoom resetZoom-usage">
                                            <Grid className="entryLimit-lineChart">
                                                <FormControl variant="standard" className='selectEntry-pie'>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        value={recLimit}
                                                        onChange={handleChangeTopAppLimit}
                                                    >
                                                        <MenuItem value="all">
                                                            <em>All</em>
                                                        </MenuItem>
                                                        <MenuItem value={5}>5</MenuItem>
                                                        <MenuItem value={10}>10</MenuItem>
                                                        <MenuItem value={20}>20</MenuItem>
                                                        <MenuItem value={30}>30</MenuItem>
                                                        <MenuItem value={50}>50</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </div>
                                        <img className={chartType == 'spline' ? "chart-view-icons-enable margin-right-10" : 'margin-right-10'} onClick={() => { setChartType('column') }} src={chartType == 'column' ? barIcon : bar_chart} />
                                        <img className={chartType == 'column' ? "chart-view-icons-enable": ''} onClick={() => { setChartType('spline') }} src={chartType == 'spline' ? line_chart : lineIcon} />
                                        {resetDate.length > 0 &&
                                            <div>
                                                <FormControlLabel
                                                    value="Zoom Back"
                                                    control={<IconButton onClick={handleResetZoom}><ZoomOutIcon /></IconButton>}
                                                    label=""
                                                    labelPlacement="start"
                                                />
                                                <FormControlLabel
                                                    value="Zoom Reset"
                                                    control={<IconButton onClick={handleDefaultResetZoom}><RestartAltIcon /></IconButton>}
                                                    label=""
                                                    labelPlacement="start"
                                                />
                                            </div>
                                        }
                                    </>}
                                </Grid>
                            </Grid>
                            <HighchartsReact
                                immutable={true}
                                highcharts={Highcharts}
                                options={barOptions}
                                ref={chartRef}
                            />
                            {chartView == 'spline' && <Grid className="topApplicationNames cf-legends">
                                {lineChartData?.map((ele, i) => (
                                    <Grid key={i} className="name-color line-cf-chart">
                                        <div className="totalUsageColor" style={{ background: ele['color'] }}></div>
                                        <Grid className={lineChartData[i]?.visible ? "lineChart-legends" : 'disable-lineChart-legends'}
                                            onClick={() => handleLegendClick(ele, ele['color'], i)}>{ele['name']}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {popupOpen ? <Dialog onClose={() => setPopupOpen(false)} open={popupOpen} className="" >
                <DialogTitle className="histogramDialogTitle">
                    <Grid item container style={{ color: "#45464E" }}>
                        Filters
                        <Grid style={{ marginLeft: "auto" }}>
                            <img src={close} onClick={() => setPopupOpen(false)} className="viewUserCloseIcon" />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className="viewUserDialogContent menuScroll histogram-dialog" >
                    <Grid>
                        <div className="bucket-outer">
                            <div className="bucket-text">{`Min (${barChartDataUnit})`}</div>
                            <Autocomplete
                                disablePortal
                                value={minBucket}
                                id="combo-box-demo"
                                onInputChange={(e, newValue) => {
                                    if (Number(newValue) >= 0 && rangeList.findIndex((o) => o.value === Number(newValue)) === -1) {
                                        setRangeList((o) => [...o, { label: `${newValue}`, value: Number(newValue) }]);
                                    }
                                }}
                                ListboxProps={{ style: { maxHeight: 150 } }}
                                options={rangeList}
                                sx={{ width: "150px", marginRight: "10px" }}
                                renderInput={(params) => (
                                    <TextField
                                        variant="standard"
                                        {...params}
                                    />
                                )}
                                getOptionDisabled={(option) => option.value >= maxBucket}
                                onChange={(e, newValue: any) => {
                                    if (newValue && newValue?.value) {
                                        setMinBucket(newValue?.value);
                                    }

                                }}
                            />
                        </div>
                    </Grid>
                    <Grid>
                        <div className="bucket-outer">
                            <div className="bucket-text">{`Max (${barChartDataUnit})`}</div>
                            <Autocomplete
                                value={maxBucket}
                                disablePortal
                                id="combo-box-demo"
                                onInputChange={(e, newValue) => {
                                    if (Number(newValue) >= 0 && rangeList.findIndex((o) => o.value === Number(newValue)) === -1) {
                                        setRangeList((o) => [...o, { label: `${newValue}`, value: Number(newValue) }]);
                                    }
                                }}
                                options={rangeList}
                                ListboxProps={{ style: { maxHeight: 150 } }}
                                sx={{ width: "150px", marginRight: "10px" }}
                                renderInput={(params) => (
                                    <TextField
                                        variant="standard"
                                        {...params}
                                    />
                                )}
                                getOptionDisabled={(option) => option.value <= minBucket}
                                onChange={(e, newValue: any) => {
                                    if (newValue && newValue?.value) {
                                        setMaxBucket(newValue?.value);
                                    }
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid >
                        <Grid>
                            <div className="bucket-outer">
                                <div className="bucket-text">Bins</div>
                                <div className="bucket-padding">
                                    <FormControl variant="standard" >
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={binSize ? binSize : 5}
                                            onChange={handleBucket}
                                            sx={{ width: "150px" }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={15}>15</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </Grid>
                        <Grid>
                            <div className="bucket-outer">
                                <div className="bucket-text">Reset</div>
                                <FormControlLabel
                                    value="Reset"
                                    control={<IconButton sx={{ marginRight: "20px" }} onClick={handleHistogramResetClick}><RestartAltIcon /></IconButton>}
                                    label=""
                                    labelPlacement="start"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Button
                        sx={{ float: "right" }}
                        className="audit-filter-apply"
                        id="captive-histogram-filter-btn"
                        onClick={handleHistogramClick}
                        variant="contained">
                        OK
                    </Button>
                </DialogContent>
            </Dialog> : null}
            {siteDetailsPopup && <Dialog
                open={siteDetailsPopup}
                onClose={() => setSiteDetailsPopup(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="menuScroll"
            >
                <DialogTitle className='delete-content-filter-title'>
                    <span className="content-filter-title-name">Subscriber Usage</span>
                    <img src={close} title="Close" onClick={() => setSiteDetailsPopup(false)} className="create-content-filter-close-btn" />
                </DialogTitle>
                <DialogContent className="dialog-list-content-filter-dialog-content">
                    <TableContainer className="content_filter--domain-names-table_container">
                        <Table sx={{ minWidth: 600, maxHeight: 200 }} size="small" aria-label="content_filter--service_accounts">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <span>S. No.</span>
                                    </TableCell>
                                    <TableCell>
                                        <span>Subscriber Name</span>
                                    </TableCell>
                                    <TableCell>
                                    <span>{`Usage (${barChartDataUnit})`}</span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="tableBody alertsTable-tableBody">
                                {
                                    selectedSiteDetails.length == 0 ?
                                        <></>
                                        :
                                        <>
                                            {
                                                selectedSiteDetails.map((sb, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell>
                                                                {sb.userName}
                                                            </TableCell>
                                                            <TableCell>
                                                            {barChartDataUnit == 'GB' ? readableBytesAsGB(sb.quotaUsed, false) : readableBytesAsMB(sb.quotaUsed, false)}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>}
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state?.authReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    getCDPlansSubscribersHistogram: state?.authReducer?.getCDPlansSubscribersHistogram,
    getCDPlansUsageLineChart: state?.authReducer?.getCDPlansUsageLineChart,
    getCDPlansSubscribersLineChart: state?.authReducer?.getCDPlansSubscribersLineChart,
});

export default withRouter(
    connect(mapStateToProps, { getCaptiveDashPlansSubscribersHistogram, getCaptiveDashPlansUsageLineCharts, getCaptiveDashPlansSubscribersLineCharts })(PlansCharts)
);